import { transparentPixel } from 'resources/base64';

const FILER_ROOT = process.env.FILER_ROOT;
const defaultPhotos = {
    user: '/assets/images/default-member.svg',
    product: '/assets/images/products/product-default-margin.png',
    productCategory: '/assets/images/products/product-1.png',
    productIcon: '/assets/images/products/product-default-icon.png',
    assembly: '/assets/images/icons/icon-mam-avatar.svg',
    product_svg: '/assets/images/default-picture.svg',
};

/**
 * Available size options: 'small', 'medium', 'large', 'banner'
 */
export function filerPhoto(photoObjectOrId, size = 'medium', defaultType) {
    if (!photoObjectOrId) {
        if (defaultType) {
            return defaultPhotos[defaultType] || transparentPixel;
        }
        return transparentPixel;
    }
    const photoId = photoObjectOrId.id || photoObjectOrId;
    return `${FILER_ROOT}/photo/${photoId}/view/${size}`;
}

export const getProductSrc = (photoId, productCategoryId, size = 'medium') => {
    if (!photoId) {
        const categoryId = productCategoryId || 'default';
        return `/assets/images/products/pi/product-${categoryId}.png`;
    }
    return filerPhoto(photoId, size, 'product');
};
